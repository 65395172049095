import { Flex, Text, Image } from '@chakra-ui/react';
import { cardsContentFallback } from '@app/cms/homeContent';

export const CardsSection = ({
  cardsSectionContent,
}: CardsSectionProps): JSX.Element => {
  return (
    <Flex background="white" w="full" justifyContent="center">
      <Flex
        flexDir="column"
        h="fit-content"
        py={{ base: '4.5rem', lg: '7.5rem' }}
        px={{ base: '1rem', md: '4rem', '2xl': 0 }}
        gap={{ base: '2rem', lg: '4.5rem' }}
        w={{ base: 'full', '2xl': '75%' }}
      >
        <Text
          color="green.300"
          fontSize={{ base: '2rem', lg: '2.75rem' }}
          fontWeight="500"
          lineHeight="120%"
          letterSpacing="tight"
        >
          {cardsSectionContent.sectionSubtitle}
        </Text>
        <Flex gap="2.5rem" direction={{ base: 'column', md: 'row' }}>
          {cardsSectionContent.cards?.map(
            ({ imageURL, descriptionText }, index) => (
              <Flex
                key={descriptionText}
                direction="column"
                maxW={{ base: 'full', md: '16rem' }}
                gap="1rem"
              >
                <Image
                  src={
                    imageURL ??
                    cardsContentFallback.content.sectionContent[index].imageURL
                  }
                  w={{ base: 10, '2xl': 12 }}
                  h={{ base: 10, '2xl': 12 }}
                  alt={`Ícone ${descriptionText}`}
                />
                <Text>{descriptionText}</Text>
              </Flex>
            )
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
type SectionCards = {
  imageURL: string;
  descriptionText: string;
};
type CardsSectionProps = {
  cardsSectionContent: {
    sectionSubtitle: string;
    cards: SectionCards[];
  };
};
