import type { GetStaticPropsResult } from 'next';
import { useRouter } from 'next/router';
import { Flex } from '@chakra-ui/react';
import type { ContentType } from '@app/interfaces/PageContents';
import { useFundamentalist } from '@app/services/graphql/hooks/useFundamentalist';
import { useRecommendedWallet } from '@app/services/graphql/hooks/useRecommendedWallet';
import { getAboutSection } from '@app/services/graphql/queries/graphcms/content/aboutSection';
import { getBeginSection } from '@app/services/graphql/queries/graphcms/content/beginSection';
import { getBusinessDirectorSection } from '@app/services/graphql/queries/graphcms/content/businessDirectorSection';
import { getCardsSection } from '@app/services/graphql/queries/graphcms/content/cardsSection';
import { getExclusiveAccessSection } from '@app/services/graphql/queries/graphcms/content/exclusiveAccessSection';
import { getFinalCTASection } from '@app/services/graphql/queries/graphcms/content/finalCTASection';
import { getPresentation } from '@app/services/graphql/queries/graphcms/content/presentationSection';
import { getResearchTeam } from '@app/services/graphql/queries/graphcms/content/researchTeamSection';
import { useWalletContext } from '@app/context/WalletContext';
import { useSaveUtm } from '@app/hooks/useSaveUtm';
import { AboutSection } from '@app/components/Modules/AboutSection';
import { BeginSection } from '@app/components/Modules/BeginSection';
import { BusinessDirectorSection } from '@app/components/Modules/BusinessDirectorSection';
import { CardsSection } from '@app/components/Modules/CardsSection';
import { ExclusiveAccess } from '@app/components/Modules/ExclusiveAccess';
import { FinalCTA } from '@app/components/Modules/FinalCTA';
import { Presentation } from '@app/components/Modules/Presentation';
import { ResearchTeam } from '@app/components/Modules/ResearchTeam';

export const Home = ({
  presentation,
  beginSection,
  cardSection,
  exclusiveAccess,
  aboutSection,
  businessDirectorSection,
  researchTeam,
  finalCTAContent,
}: HomeProps) => {
  const router = useRouter();
  const { setRecommended, setTickers } = useWalletContext();
  useSaveUtm();
  useFundamentalist({
    onSuccess: ({ data }) => setTickers(data),
  });
  useRecommendedWallet({
    onSuccess: ({ wallets }) => setRecommended(wallets),
  });
  const startForm = () => router.push('/form', undefined, { shallow: true });
  return (
    <Flex
      w="full"
      flexDirection="column"
      alignItems={{ base: 'unset', '2xl': 'center' }}
    >
      <Presentation
        handleClick={startForm}
        presentationContent={presentation.sectionPresentation}
      />
      <BeginSection beginSectionContent={beginSection.sectionBegin} />
      <ExclusiveAccess
        exclusiveAccessContent={exclusiveAccess.sectionExclusiveAccess}
      />
      <CardsSection cardsSectionContent={cardSection.sectionCard} />
      <AboutSection aboutSectionContent={aboutSection.sectionAbout} />
      <BusinessDirectorSection
        businessDirectorSectionContent={
          businessDirectorSection.sectionBusinessDirector
        }
      />
      <ResearchTeam researchTeamContent={researchTeam.sectionResearchTeam} />
      <FinalCTA
        handleClick={startForm}
        finalCTAContent={finalCTAContent.sectionCTAFinal}
      />
    </Flex>
  );
};

export default Home;

export const getStaticProps = async (): Promise<
  GetStaticPropsResult<HomeProps>
> => {
  const { data: presentation } = await getPresentation();
  const { data: beginSection } = await getBeginSection();
  const { data: exclusiveAccess } = await getExclusiveAccessSection();
  const { data: cardSection } = await getCardsSection();
  const { data: aboutSection } = await getAboutSection();
  const { data: businessDirectorSection } = await getBusinessDirectorSection();
  const { data: researchTeam } = await getResearchTeam();
  const { data: finalCTAContent } = await getFinalCTASection();
  return {
    props: {
      presentation,
      beginSection,
      exclusiveAccess,
      cardSection,
      aboutSection,
      businessDirectorSection,
      researchTeam,
      finalCTAContent,
    },
  };
};

type HomeProps = {
  presentation: ContentType;
  beginSection: ContentType;
  exclusiveAccess: ContentType;
  aboutSection: ContentType;
  cardSection: ContentType;
  businessDirectorSection: ContentType;
  researchTeam: ContentType;
  finalCTAContent: ContentType;
};
