import { Flex } from '@chakra-ui/react';
import { businessDirectorSectionFallback } from '@app/cms/homeContent';
import { ImageSection } from '@app/components/Elements/ImageSection';
import { SectionContent } from '../SectionContent';

export const BusinessDirectorSection = ({
  businessDirectorSectionContent,
}: businessDirectorSectionContentProps): JSX.Element => {
  return (
    <Flex background="white.150" w="full" justifyContent="center">
      <Flex
        h="fit-content"
        py={{ base: '4.5rem', lg: '7.5rem' }}
        px={{ base: '1rem', md: '4rem', '2xl': 0 }}
        gap={{ base: '2rem', lg: '7.5rem' }}
        w={{ base: 'full', '2xl': '75%' }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <ImageSection
          imageAlt="Luís Moran"
          imageUrlFallback={businessDirectorSectionFallback.content.imageURL}
          imageUrl={businessDirectorSectionContent?.imageURL}
        />

        <SectionContent
          contentMaxW="33rem"
          {...businessDirectorSectionContent}
        />
      </Flex>
    </Flex>
  );
};

type businessDirectorSectionContentProps = {
  businessDirectorSectionContent: {
    sectionTitle: string;
    sectionSubtitle: string;
    sectionContent: string[];
    imageURL: string;
  };
};
