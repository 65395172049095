import { Flex } from '@chakra-ui/react';
import { SectionContent } from '../SectionContent';

export const ExclusiveAccess = ({
  exclusiveAccessContent,
}: exclusiveAccessProps): JSX.Element => {
  return (
    <Flex background="green.300" w="full" justifyContent="center">
      <Flex
        h="fit-content"
        py={{ base: '4.5rem', lg: '7.5rem' }}
        px={{ base: '1rem', md: '4rem', '2xl': 0 }}
        gap={{ base: '2rem', lg: '7.5rem' }}
        w={{ base: 'full', '2xl': '75%' }}
      >
        <SectionContent
          subtitleMaxW="55rem"
          theme="dark"
          contentSpacing="1.75rem"
          hasIcons
          {...exclusiveAccessContent}
        />
      </Flex>
    </Flex>
  );
};

type exclusiveAccessProps = {
  exclusiveAccessContent: {
    sectionTitle: string;
    sectionSubtitle: string;
    sectionContent: string[];
    sectionButtonText: string;
  };
};
