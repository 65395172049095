export const presentationFallback = {
  content: {
    sectionButtonText: 'Começar',
    sectionDescription:
      'Para otimizar sua carteira atual, avaliar o desempenho de suas ações e identificar novas oportunidades de investimento.',
    sectionTitle: 'Guia de ações',
  },
};

export const beginSectionContentFallback = {
  content: {
    sectionTitle: 'Melhores ações para o seu portfólio',
    sectionSubtitle: 'Como encontrar as melhores ações para você comprar?',
    imageURL: 'https://media.graphassets.com/639LXINOSXiwlf1Az2DA',
    sectionContent: [
      'Atualmente existem mais de 400 ações listadas na Bolsa de Valores, cada uma representando os diversos setores da economia – como commodities, energia, financeiro, etc.',
      'Com uma quantidade grande como essa, é difícil que você tenha consciência de todas as oportunidades disponíveis. Além disso, se você já tem experiência investindo no mercado de ações, sabe que ele tende a ser imprevisível...',
      'Mas isso não significa que seja impossível você ter uma ideia de como será o futuro desempenho desses ativos!',
      'Com o Guia de Ações da EQI/Research, você terá acesso a todos os dados essenciais, incluindo projeções de valorização, para selecionar as melhores ações para o seu portfólio, embasando suas escolhas em análises fundamentadas.',
    ],
    sectionButtonText: 'Iniciar',
  },
};

export const exclusiveAccessContentFallback = {
  content: {
    sectionTitle: 'Acesso exclusivo',
    sectionSubtitle: 'Com o Guia de Ações da EQI/Research, você terá acesso a:',
    sectionContent: [
      'Análises dos ativos, com projeções de preço, potencial de valorização e recomendações de compra e venda para cada ação.',
      'Comparação da sua carteira de investimentos com a carteira recomendada da EQI/Research, para você identificar oportunidades de melhoria.',
      'Visão abrangente do desempenho dos seus investimentos, para tomar decisões informadas e maximizar seus retornos.',
      'BÔNUS: Acesso a versão Especialista do Guia de Ações, onde você vai encontrar uma pesquisa inovadora realizada pelos profissionais da EQI/Research.',
    ],
    sectionButtonText: 'Começar',
  },
};

export const cardsContentFallback = {
  content: {
    sectionTitle: 'Este material foi desenvolvido para:',
    sectionContent: [
      {
        imageURL: 'https://media.graphassets.com/lAHLBpQGSeWf9PYItZ62',
        descriptionText:
          'Quem já investe em ações e quer melhorar o desempenho da carteira de investimentos',
      },
      {
        imageURL: 'https://media.graphassets.com/nfVNmq94SB67q6Vvzw62',
        descriptionText:
          'Quem entende a importância de ter um portfólio diversificado e está em busca de novas oportunidades de investimentos',
      },
      {
        imageURL: 'https://media.graphassets.com/rprFNHTAQnirRihWYd1G',
        descriptionText:
          'Quem gostaria de ter maior previsibilidade no mercado de ações',
      },
      {
        imageURL: 'https://media.graphassets.com/vILONdSOSWqwI3MggOpZ',
        descriptionText:
          'Quem entende a importância de investir em renda variável para buscar maior retornos no longo prazo',
      },
    ],
  },
};

export const aboutContentFallback = {
  content: {
    sectionTitle: 'O mercado fala, a gente traduz',
    sectionSubtitle:
      'A EQI/Research nasceu com um propósito muito simples, mas ambicioso:',
    sectionContent: [
      'Oferecer qualidade, serviços e experiências dos grandes players do mercado para você, pessoa física.',
      'Tudo com uma linguagem acessível, para que você usufrua de teses e análises aprofundadas de investimentos como os investidores institucionais sempre usufruíram...',
      'Na EQI/Research, você tem acesso a:',
      '→ Relatórios completos e detalhados;',
      '→ Análises fundamentalistas;',
      '→ Análises técnicas;',
      '→ Carteiras recomendadas;',
      '→ Cobertura de Ações e Fundos Imobiliários (FIIs);',
      '→ Transmissões ao vivo com profissionais do mercado de renda variável.',
    ],
  },
};

export const businessDirectorSectionFallback = {
  content: {
    sectionTitle: 'Head da EQI Research',
    sectionSubtitle: 'Quem está por trás do Guia de Ações?',
    imageURL: 'https://media.graphassets.com/jDZJhATBQJlu6DbVDcuA',
    sectionContent: [
      'Luís Moran possui quase 30 anos de experiência profissional em finanças, tendo atuado como executivo de relações com investidores, gestor de recursos e analista de investimentos, e construído sua carreira em empresas como WEG, Itaú Corretora, BNP Paribas, Bradesco Templeton, Santander e Unibanco.',
      'Moran também foi diretor do IBRI (Instituto Brasileiro de Relações com Investidores) por duas gestões e desde 2021 é Head da EQI/Research.',
    ],
  },
};
