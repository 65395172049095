import {
  Image,
  Text,
  Flex,
  useBreakpointValue,
  IconButton,
} from '@chakra-ui/react';
import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export const Carousel = ({ content }: CarouselProps): JSX.Element => {
  const imageSize = useBreakpointValue(
    {
      base: { h: '450px', w: '275px' },
      '2xl': { h: '600px', w: '366px' },
    },
    {
      fallback: 'base',
    }
  );

  const carouselStyle = useBreakpointValue(
    {
      base: {
        maxWidth: '100%',
        paddingLeft: '1rem',
        margin: '0',
      },
      md: {
        maxWidth: '100%',
        paddingLeft: '4rem',
        margin: '0',
      },
      '2xl': {
        maxWidth: '75%',
        margin: '0',
        overflow: 'visible',
      },
    },
    {
      fallback: 'md',
    }
  );

  const slides = content?.map(({ description, image, title }) => (
    <SwiperSlide
      style={{
        minWidth: imageSize?.w || '275px',
        maxWidth: imageSize?.w || '275px',
      }}
      key={title}
    >
      <Flex position="relative" color="white.500" {...imageSize}>
        <Image
          borderRadius="1rem"
          src={image}
          alt={`${title} ${description}`}
          {...imageSize}
        />
        <Flex
          flexDirection="column"
          position="absolute"
          top={{ base: '75%', '2xl': '80%' }}
          pl={6}
          gap={2}
        >
          <Text fontWeight="semibold" fontSize="2xl" lineHeight="base">
            {title}
          </Text>
          <Text fontSize="sm" fontWeight="normal">
            {description}
          </Text>
        </Flex>
      </Flex>
    </SwiperSlide>
  ));
  return (
    <Flex w="full" overflow="hidden" justifyContent="center">
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{
          nextEl: `.next-slide`,
          prevEl: `.prev-slide`,
        }}
        pagination={{
          type: 'progressbar',
          el: '.research-team-pagination',
        }}
        slidesPerView={'auto'}
        spaceBetween={16}
        style={carouselStyle}
      >
        {slides}
      </Swiper>
      <Flex
        display={{ base: 'none', md: 'flex' }}
        w="60%"
        maxW="60rem"
        justifyContent="space-between"
        position="absolute"
        bottom={5}
      >
        <IconButton
          className="prev-slide"
          variant="ghost"
          aria-label="Voltar slide"
          _hover={{
            color: 'green.200',
          }}
          icon={<IoIosArrowDropleft size="45px" />}
        />
        <IconButton
          className="next-slide"
          variant="ghost"
          aria-label="Avançar slide"
          _hover={{
            color: 'green.200',
          }}
          icon={<IoIosArrowDropright size="45px" />}
        />
      </Flex>
      <div className="research-team-pagination"></div>
    </Flex>
  );
};

type CarouselContent = {
  title: string;
  description: string;
  image: string;
};

type CarouselProps = {
  content: CarouselContent[];
};
