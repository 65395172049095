import { Button, Flex, Text } from '@chakra-ui/react';
import { BsArrowRightShort } from 'react-icons/bs';

export const Presentation = ({
  handleClick,
  presentationContent,
}: PresentationProps): JSX.Element => {
  const { sectionButtonText, sectionContent, sectionSubtitle } =
    presentationContent;

  return (
    <Flex
      color="white"
      flexDirection="column"
      justifyContent="center"
      py={{ base: '4.5rem', lg: '7.5rem' }}
      px={{ base: '1rem', md: '4rem', '2xl': 0 }}
      gap="2rem"
      h={{
        base: 'calc(clamp(500px, 100vh, 1024px) - 7.5rem)',
        md: 'calc(clamp(744px, 100vh, 1024px) - 7.5rem)',
      }}
      w={{ base: 'full', '2xl': '75%' }}
    >
      <Text
        fontWeight="300"
        fontSize={{ base: '2rem', lg: '4rem' }}
        lineHeight="110%"
      >
        {sectionSubtitle}
      </Text>
      {sectionContent?.map((textContent) => (
        <Text
          lineHeight="taller"
          key={textContent}
          letterSpacing="tight"
          fontSize={{ base: 'md', md: 'lg' }}
          maxW="34rem"
          dangerouslySetInnerHTML={{
            __html: textContent,
          }}
        />
      ))}
      <Button
        onClick={handleClick}
        rightIcon={<BsArrowRightShort size="20px" />}
        backgroundColor="white.500"
        color="green.300"
        justifyContent="space-between"
        fontWeight="400"
        fontSize="sm"
        px="24px"
        py="24px"
        borderRadius="8px"
        maxW={{ base: 'full', md: '20rem', lg: '22rem' }}
        textTransform="uppercase"
      >
        {sectionButtonText}
      </Button>
    </Flex>
  );
};

type PresentationProps = {
  handleClick: () => void;
  presentationContent: {
    sectionSubtitle: string;
    sectionContent: string[];
    sectionButtonText: string;
  };
};
