import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { beginSectionContentFallback } from '@app/cms/homeContent';
import { ImageSection } from '@app/components/Elements/ImageSection';
import { SectionContent } from '../SectionContent';

export const BeginSection = ({
  beginSectionContent,
}: BeginSectionProps): JSX.Element => {
  const beginSectionImage = useBreakpointValue({
    base: <></>,
    lg: (
      <ImageSection
        imageAlt="Homem de negócios usando um notebook"
        imageUrl={beginSectionContent.imageURL}
        imageUrlFallback={beginSectionContentFallback.content.imageURL}
      />
    ),
  });
  return (
    <Flex background="white" w="full" justifyContent="center">
      <Flex
        h="fit-content"
        py={{ base: '4.5rem', lg: '7.5rem' }}
        px={{ base: '1rem', md: '4rem', '2xl': 0 }}
        gap={{ base: '2rem', lg: '7.5rem' }}
        w={{ base: 'full', '2xl': '75%' }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <SectionContent
          contentMaxW={{ base: 'full', lg: '33rem' }}
          subtitleMaxW={{ base: 'full', '2xl': '50rem' }}
          hasSectionImage
          {...beginSectionContent}
        />
        {beginSectionImage}
      </Flex>
    </Flex>
  );
};

type BeginSectionProps = {
  beginSectionContent: {
    sectionTitle: string;
    sectionSubtitle: string;
    sectionContent: string[];
    sectionButtonText: string;
    imageURL: string;
  };
};
