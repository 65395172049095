import { Flex } from '@chakra-ui/react';
import { Carousel } from '@app/components/Elements/Carousel';
import { SectionTitle } from '@app/components/Elements/SectionTitle';

export const ResearchTeam = ({
  researchTeamContent,
}: ResearchTeamProps): JSX.Element => {
  const { researchTeam, sectionSubtitle, sectionTitle } = researchTeamContent;
  return (
    <Flex
      flexDir="column"
      pb={{ base: 20, lg: 28 }}
      pt={{ base: 16, lg: 28 }}
      gap="3rem"
      alignItems="center"
      justifyContent="center"
      position="relative"
      w="full"
    >
      <Flex
        px={{ base: '1rem', md: '4rem', '2xl': 0 }}
        w={{ base: 'full', '2xl': '75%' }}
      >
        <SectionTitle
          theme="light"
          title={sectionTitle}
          subtitle={sectionSubtitle}
        />
      </Flex>
      <Carousel content={researchTeam} />
    </Flex>
  );
};

type ResearchTeamType = {
  title: string;
  description: string;
  image: string;
};

type ResearchTeamProps = {
  researchTeamContent: {
    sectionTitle: string;
    sectionSubtitle: string;
    researchTeam: ResearchTeamType[];
  };
};
