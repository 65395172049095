import Image from 'next/image';
import { Flex } from '@chakra-ui/react';
export const ImageSection = ({
  imageUrl,
  imageUrlFallback,
  imageAlt,
}: ImageSectionProps): JSX.Element => {
  return (
    <Flex
      position="relative"
      height="auto"
      maxH="51rem"
      maxW="50rem"
      width="full"
      style={{ aspectRatio: 576 / 632 }}
    >
      <Image
        src={imageUrl ?? imageUrlFallback}
        alt={imageAlt}
        style={{ objectFit: 'cover', borderRadius: '1rem' }}
        fill
      />
    </Flex>
  );
};

type ImageSectionProps = {
  imageUrl?: string;
  imageUrlFallback: string;
  imageAlt: string;
};
