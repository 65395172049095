import { Flex } from '@chakra-ui/react';
import { SectionContent } from '../SectionContent';

export const AboutSection = ({
  aboutSectionContent,
}: AboutSectionProps): JSX.Element => {
  return (
    <Flex
      background="linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 98%), url(/images/phone.png) no-repeat"
      backgroundSize="cover"
      backgroundPosition={{ base: 'left', lg: 'center' }}
      w="full"
      justifyContent="center"
    >
      <Flex
        h="fit-content"
        py={{ base: '4.5rem', lg: '7.5rem' }}
        px={{ base: '1rem', md: '4rem', '2xl': 0 }}
        gap={{ base: '2rem', lg: '7.5rem' }}
        w={{ base: 'full', '2xl': '75%' }}
      >
        <SectionContent
          subtitleMaxW="40rem"
          theme="dark"
          contentMaxW="33rem"
          {...aboutSectionContent}
        />
      </Flex>
    </Flex>
  );
};

type AboutSectionProps = {
  aboutSectionContent: {
    sectionTitle: string;
    sectionSubtitle: string;
    sectionContent: string[];
  };
};
