import type { LayoutProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';

const subtitleColor = {
  light: 'green.300',
  dark: 'white',
};

const fontWeight = {
  light: '600',
  dark: '500',
};

export const SectionTitle = ({
  title,
  subtitle,
  theme,
  subtitleMaxWidth,
}: SectionTitleProps) => {
  return (
    <Flex direction="column" gap=".75rem">
      <Text
        as="h2"
        color="green.100"
        textTransform="uppercase"
        fontSize="small"
        letterSpacing="wider"
        fontWeight="600"
        lineHeight="120%"
      >
        {title}
      </Text>
      <Text
        as="h3"
        color={subtitleColor[theme]}
        fontSize={{ base: '2rem', lg: '2.75rem' }}
        fontWeight={fontWeight[theme]}
        lineHeight="120%"
        maxW={subtitleMaxWidth}
      >
        {subtitle}
      </Text>
    </Flex>
  );
};

type SectionTitleProps = {
  theme: 'dark' | 'light';
  title: string;
  subtitle: string;
  subtitleMaxWidth?: LayoutProps['maxW'];
};
