import { Button, Flex, Text } from '@chakra-ui/react';
import { BsArrowRightShort } from 'react-icons/bs';

export const FinalCTA = ({
  finalCTAContent,
  handleClick,
}: FinalCTAProps): JSX.Element => {
  return (
    <Flex background="white.150" w="full" justifyContent="center">
      <Flex
        direction="column"
        h="fit-content"
        py={{ base: '5rem', lg: '7.5rem' }}
        px={{ base: '1rem', md: '4rem', '2xl': 0 }}
        gap="3rem"
        w={{ base: 'full', '2xl': '75%' }}
      >
        <Text
          as="h3"
          color="green.300"
          fontSize={{ base: '2rem', lg: '2.75rem' }}
          fontWeight="500"
          lineHeight="130%"
          letterSpacing="tight"
          maxW="70rem"
        >
          {finalCTAContent.sectionSubtitle}
        </Text>
        <Button
          rightIcon={<BsArrowRightShort size="20px" />}
          justifyContent="space-between"
          fontWeight="400"
          fontSize="sm"
          px="24px"
          py="24px"
          borderRadius="8px"
          maxW={{ base: 'full', md: '20rem' }}
          textTransform="uppercase"
          _hover={{ backgroundColor: 'green.200' }}
          color="white.500"
          backgroundColor="green.300"
          onClick={handleClick}
        >
          {finalCTAContent.sectionButtonText}
        </Button>
      </Flex>
    </Flex>
  );
};

type FinalCTAProps = {
  handleClick: () => void;
  finalCTAContent: {
    sectionSubtitle: string;
    sectionButtonText: string;
  };
};
