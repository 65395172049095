import type { LayoutProps, SpaceProps } from '@chakra-ui/react';
import { Flex, Text, Icon } from '@chakra-ui/react';
import { FiCheckSquare } from 'react-icons/fi';

const textColor = {
  light: 'black',
  dark: 'white',
};

export const SectionTexts = ({
  content,
  theme,
  maxW,
  contentSpacing = '1rem',
  hasIcons = false,
}: SectionTextProps) => {
  return (
    <Flex direction="column" maxW={maxW}>
      {content?.map((content, index) => (
        <Text
          key={content.slice(0, 10) + index}
          marginTop={index ? contentSpacing : 0}
          lineHeight="150%"
          letterSpacing="tight"
          color={textColor[theme]}
          display="flex"
          alignItems={{ base: 'flex-start', lg: 'center' }}
          gap="1rem"
        >
          {hasIcons && (
            <Icon as={FiCheckSquare} boxSize="1.2rem" color="green.100" />
          )}
          {content}
        </Text>
      ))}
    </Flex>
  );
};

type SectionTextProps = {
  content: string[];
  theme: 'light' | 'dark';
  maxW?: LayoutProps['maxW'];
  contentSpacing?: SpaceProps['margin'];
  hasIcons?: boolean;
};
