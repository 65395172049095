import Image from 'next/image';
import router from 'next/router';
import type { LayoutProps, SpaceProps } from '@chakra-ui/react';
import { Button, Flex, useBreakpointValue } from '@chakra-ui/react';
import { BsArrowRightShort } from 'react-icons/bs';
import { beginSectionContentFallback } from '@app/cms/homeContent';
import { ImageSection } from '@app/components/Elements/ImageSection';
import { SectionTexts } from '@app/components/Elements/SectionTexts';
import { SectionTitle } from '@app/components/Elements/SectionTitle';

const buttonColorScheme = {
  light: { color: 'white.500', backgroundColor: 'green.300' },
  dark: { color: 'black', backgroundColor: 'green.100' },
};

export const SectionContent = ({
  theme = 'light',
  sectionContent,
  sectionSubtitle,
  sectionTitle,
  sectionButtonText,
  contentMaxW,
  subtitleMaxW,
  contentSpacing,
  hasIcons,
  hasSectionImage,
  imageURL,
}: SectionContentProps) => {
  const sectionImage = useBreakpointValue({
    base: (
      <ImageSection
        imageUrl={imageURL}
        imageAlt="Homem de negócios usando um notebook"
        imageUrlFallback={beginSectionContentFallback.content.imageURL}
      />
    ),
    lg: <></>,
  });
  return (
    <Flex flexDir="column" w="full" gap="2rem">
      <SectionTitle
        subtitleMaxWidth={subtitleMaxW}
        title={sectionTitle}
        subtitle={sectionSubtitle}
        theme={theme}
      />
      {hasSectionImage && sectionImage}
      <SectionTexts
        contentSpacing={contentSpacing}
        maxW={contentMaxW}
        content={sectionContent}
        theme={theme}
        hasIcons={hasIcons}
      />
      {sectionButtonText && (
        <Button
          rightIcon={<BsArrowRightShort size="20px" />}
          justifyContent="space-between"
          fontWeight="400"
          fontSize="sm"
          px="24px"
          py="24px"
          borderRadius="8px"
          maxW={{ base: 'full', md: '22rem' }}
          textTransform="uppercase"
          _hover={{ backgroundColor: 'green.200' }}
          onClick={() => router.push('/form', undefined, { shallow: true })}
          {...buttonColorScheme[theme]}
        >
          {sectionButtonText}
        </Button>
      )}
    </Flex>
  );
};

type SectionContentProps = {
  theme?: 'dark' | 'light';
  sectionTitle: string;
  sectionSubtitle: string;
  sectionContent: string[];
  sectionButtonText?: string;
  subtitleMaxW?: LayoutProps['maxW'];
  contentMaxW?: LayoutProps['maxW'];
  contentSpacing?: SpaceProps['margin'];
  hasIcons?: boolean;
  imageURL?: string;
  hasSectionImage?: boolean;
};
